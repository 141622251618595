import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Card as RCard, Flex, Box, Heading, Text } from 'rebass'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import Container from '../components/container'
import SEO from '../components/seo'

const Card = styled(RCard)`
  height: 100%;

  h2 {
    color: #023a51;
  }
`

const CourseDetails = ({ link, image, title, description }) => (
  <Box width={[1, 1 / 2, 1 / 4]} mb={[3, 3, 0]} px={3} py={3}>
    <Link to={link}>
      <Card
        borderRadius={4}
        boxShadow="1px 1px 4px 1px hsla(0,0%,0%,0.1)"
        minHeight="102px"
        p={2}
      >
        <Img
          fluid={image.childImageSharp.fluid}
          css={{ borderRadius: '2px' }}
          alt={title}
        />
        <Heading fontWeight="600" fontSize={'18px'} mt={3} mb={2}>
          {title}
        </Heading>
        <Text lineHeight="130%" fontSize={2} color={'#383838'} mb={3}>
          {description}
        </Text>
      </Card>
    </Link>
  </Box>
)

const FormazionePage = ({ data }) => {
  const { nodes } = data.allMarkdownRemark

  return (
    <Layout>
      <SEO title="Formazione" />
      <Box p={5} bg="#D9E8ED">
        <Heading
          fontSize={6}
          fontWeight="500"
          textAlign="center"
          fontFamily="Playfair Display, serif"
        >
          Corsi di formazione
        </Heading>
      </Box>
      <Container mb={4}>
        <Flex p={3} flexWrap="wrap" mx={-3}>
          {nodes.map(({ fields, frontmatter }) => (
            <CourseDetails
              link={fields.slug}
              image={frontmatter.image}
              title={frontmatter.title}
              description={frontmatter.description}
              key={fields.slug}
            />
          ))}
        </Flex>
      </Container>
    </Layout>
  )
}

export const CoursesQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { fileAbsolutePath: { regex: "/formazione/" } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default FormazionePage
